<template>
  <div>
    <slot name="change-email" />
  </div>
</template>

<script>
export default {

}
</script>